@import "/src/styles";

.card {
  border-radius: $border-radius !important;
  transition: all .5s;

  &:hover {
    transform: translateY(-0.3rem);
  }
}

.info-container {
  max-width: calc(100% - 31.7rem);
}

.image {
  width: 8.7rem;
  height: 12.2rem;
  border: 1px solid var(--gray-3);
}

.title {
  font-weight: 700 !important;
  @include multiline-ellipsis(3);
}

.date {
  display: inline;
  @include font-size-18;
}

.content-type {
  @include multiline-ellipsis(2);
}

.description {
  @include multiline-ellipsis(3);
}

.source {
  @include font-size-12;
}

.price {
  width: 15rem !important;
  padding-left: 6.2rem;
  margin-left: auto;
  height: auto;
  border-left: 1px solid var(--border-color);
}

.price-text {
  color: var(--blue);
  @include font-size-18;
}
