@import "/src/styles";

.container {
  padding: $padding-sm;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  border: 1px solid transparent;
  width: 15.5rem;
  margin-top: $margin-xxs;

  &:hover {
    border-color: var(--blue);
    border-radius: $border-radius;

    & .title {
      color: var(--blue) !important;
    }
  }
}

.avatar {
  width: 5.4rem;
  height: 5.4rem;
  border-radius: 50%;
  margin-bottom: .5rem;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='4' r='3' stroke='%23C5C5C5' strokeWidth='2'/%3E%3Cpath d='M1 14C2.85765 12.1344 5.31076 11 8 11C10.6892 11 13.1424 12.1344 15 14' stroke='%23C5C5C5' strokeWidth='2' strokeLinecap='round'/%3E%3C/svg%3E") !important;
  background-size: 2rem !important;
  background-color: var(--gray-4);
}

.anonymous {
  margin-right: .6rem;
}

.logout {
  margin-left: 1rem;
  margin-top: $margin-xxs;
  border: 1px solid var(--border-color);
  border-radius: $border-radius;
  width: 6rem;
  cursor: pointer;

  &:hover {
    border-color: var(--blue);

    & .logout-icon {
      transition: transform .4s;
      color: var(--blue);
      transform: scale(1.1, 1.1)
    }
  }
}

.logout-icon {
  @include font-size-20;
}
